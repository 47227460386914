function ActualsRepo({ GRAPHQL_URL, GRAPHQL_API_KEY, httpClient }) {
  this.getActuals = ({ year, token }, { success, failure }) => {
    const query = `mutation ($token: String!, $year: Int!) {
                      getUsageDetailsForUser(actualsSearch: {token: $token, year: $year}) {
                        data {
                          element1
                          element2
                          element3
                          element4
                          values {
                            _0
                            _1
                            _10
                            _11
                            _2
                            _3
                            _4
                            _5
                            _6
                            _7
                            _8
                            _9
                          }
                        }
                        error {
                          code
                          message
                          statusCode
                        }
                      }
                    }`

    httpClient.request(
      {
        method: 'POST',
        url: GRAPHQL_URL,
        headers: {
          'x-api-key': GRAPHQL_API_KEY
        },
        data: {
          query,
          variables: {
            token,
            year
          }
        }
      },
      {
        success: (response) => {
          const { getUsageDetailsForUser } = response.data.data
          success(getUsageDetailsForUser)
        },
        failure: (error) => failure(error)
      }
    )
  }

  this.getActualsNew = ({ year, token }, { success, failure }) => {
    const query = `mutation ($token: String!, $year: Int!) {
                      getUsageDetailsForUserNew(actualsSearch: {token: $token, year: $year}) {
                        data {
                          element1
                          element2
                          element3
                          element4
                          accountId
                          percentage
                          startDate
                          endDate
                          values {
                            _0 {
                              cost
                              credit
                              tax
                              discountedUsage
                              fee
                              refund
                              riFee
                              savingPlanRecurringFee
                              savingPlanUpfrontFee
                              savingsPlanCoverage
                              savingsPlanNegation
                            }
                            _1 {
                              cost
                              credit
                              tax
                              discountedUsage
                              fee
                              refund
                              riFee
                              savingPlanRecurringFee
                              savingPlanUpfrontFee
                              savingsPlanCoverage
                              savingsPlanNegation
                            }
                            _2 {
                              cost
                              credit
                              tax
                              discountedUsage
                              fee
                              refund
                              riFee
                              savingPlanRecurringFee
                              savingPlanUpfrontFee
                              savingsPlanCoverage
                              savingsPlanNegation
                            }
                            _3 {
                              cost
                              credit
                              tax
                              discountedUsage
                              fee
                              refund
                              riFee
                              savingPlanRecurringFee
                              savingPlanUpfrontFee
                              savingsPlanCoverage
                              savingsPlanNegation
                            }
                            _4 {
                              cost
                              credit
                              tax
                              discountedUsage
                              fee
                              refund
                              riFee
                              savingPlanRecurringFee
                              savingPlanUpfrontFee
                              savingsPlanCoverage
                              savingsPlanNegation
                            }
                            _5 {
                              cost
                              credit
                              tax
                              discountedUsage
                              fee
                              refund
                              riFee
                              savingPlanRecurringFee
                              savingPlanUpfrontFee
                              savingsPlanCoverage
                              savingsPlanNegation
                            }
                            _6 {
                              cost
                              credit
                              tax
                              discountedUsage
                              fee
                              refund
                              riFee
                              savingPlanRecurringFee
                              savingPlanUpfrontFee
                              savingsPlanCoverage
                              savingsPlanNegation
                            }
                            _7 {
                              cost
                              credit
                              tax
                              discountedUsage
                              fee
                              refund
                              riFee
                              savingPlanRecurringFee
                              savingPlanUpfrontFee
                              savingsPlanCoverage
                              savingsPlanNegation
                            }
                            _8 {
                              cost
                              credit
                              tax
                              discountedUsage
                              fee
                              refund
                              riFee
                              savingPlanRecurringFee
                              savingPlanUpfrontFee
                              savingsPlanCoverage
                              savingsPlanNegation
                            }
                            _9 {
                              cost
                              credit
                              tax
                              discountedUsage
                              fee
                              refund
                              riFee
                              savingPlanRecurringFee
                              savingPlanUpfrontFee
                              savingsPlanCoverage
                              savingsPlanNegation
                            }
                            _10 {
                              cost
                              credit
                              tax
                              discountedUsage
                              fee
                              refund
                              riFee
                              savingPlanRecurringFee
                              savingPlanUpfrontFee
                              savingsPlanCoverage
                              savingsPlanNegation
                            }
                            _11 {
                              cost
                              credit
                              tax
                              discountedUsage
                              fee
                              refund
                              riFee
                              savingPlanRecurringFee
                              savingPlanUpfrontFee
                              savingsPlanCoverage
                              savingsPlanNegation
                            }
                          }
                          claimedValues {
                            _0 {
                              cost
                              credit
                              tax
                              discountedUsage
                              fee
                              refund
                              riFee
                              savingPlanRecurringFee
                              savingPlanUpfrontFee
                              savingsPlanCoverage
                              savingsPlanNegation
                            }
                            _1 {
                              cost
                              credit
                              tax
                              discountedUsage
                              fee
                              refund
                              riFee
                              savingPlanRecurringFee
                              savingPlanUpfrontFee
                              savingsPlanCoverage
                              savingsPlanNegation
                            }
                            _2 {
                              cost
                              credit
                              tax
                              discountedUsage
                              fee
                              refund
                              riFee
                              savingPlanRecurringFee
                              savingPlanUpfrontFee
                              savingsPlanCoverage
                              savingsPlanNegation
                            }
                            _3 {
                              cost
                              credit
                              tax
                              discountedUsage
                              fee
                              refund
                              riFee
                              savingPlanRecurringFee
                              savingPlanUpfrontFee
                              savingsPlanCoverage
                              savingsPlanNegation
                            }
                            _4 {
                              cost
                              credit
                              tax
                              discountedUsage
                              fee
                              refund
                              riFee
                              savingPlanRecurringFee
                              savingPlanUpfrontFee
                              savingsPlanCoverage
                              savingsPlanNegation
                            }
                            _5 {
                              cost
                              credit
                              tax
                              discountedUsage
                              fee
                              refund
                              riFee
                              savingPlanRecurringFee
                              savingPlanUpfrontFee
                              savingsPlanCoverage
                              savingsPlanNegation
                            }
                            _6 {
                              cost
                              credit
                              tax
                              discountedUsage
                              fee
                              refund
                              riFee
                              savingPlanRecurringFee
                              savingPlanUpfrontFee
                              savingsPlanCoverage
                              savingsPlanNegation
                            }
                            _7 {
                              cost
                              credit
                              tax
                              discountedUsage
                              fee
                              refund
                              riFee
                              savingPlanRecurringFee
                              savingPlanUpfrontFee
                              savingsPlanCoverage
                              savingsPlanNegation
                            }
                            _8 {
                              cost
                              credit
                              tax
                              discountedUsage
                              fee
                              refund
                              riFee
                              savingPlanRecurringFee
                              savingPlanUpfrontFee
                              savingsPlanCoverage
                              savingsPlanNegation
                            }
                            _9 {
                              cost
                              credit
                              tax
                              discountedUsage
                              fee
                              refund
                              riFee
                              savingPlanRecurringFee
                              savingPlanUpfrontFee
                              savingsPlanCoverage
                              savingsPlanNegation
                            }
                            _10 {
                              cost
                              credit
                              tax
                              discountedUsage
                              fee
                              refund
                              riFee
                              savingPlanRecurringFee
                              savingPlanUpfrontFee
                              savingsPlanCoverage
                              savingsPlanNegation
                            }
                            _11 {
                              cost
                              credit
                              tax
                              discountedUsage
                              fee
                              refund
                              riFee
                              savingPlanRecurringFee
                              savingPlanUpfrontFee
                              savingsPlanCoverage
                              savingsPlanNegation
                            }
                          }
                          sharedValues {
                            _0 {
                              cost
                              credit
                              tax
                              discountedUsage
                              fee
                              refund
                              riFee
                              savingPlanRecurringFee
                              savingPlanUpfrontFee
                              savingsPlanCoverage
                              savingsPlanNegation
                            }
                            _1 {
                              cost
                              credit
                              tax
                              discountedUsage
                              fee
                              refund
                              riFee
                              savingPlanRecurringFee
                              savingPlanUpfrontFee
                              savingsPlanCoverage
                              savingsPlanNegation
                            }
                            _2 {
                              cost
                              credit
                              tax
                              discountedUsage
                              fee
                              refund
                              riFee
                              savingPlanRecurringFee
                              savingPlanUpfrontFee
                              savingsPlanCoverage
                              savingsPlanNegation
                            }
                            _3 {
                              cost
                              credit
                              tax
                              discountedUsage
                              fee
                              refund
                              riFee
                              savingPlanRecurringFee
                              savingPlanUpfrontFee
                              savingsPlanCoverage
                              savingsPlanNegation
                            }
                            _4 {
                              cost
                              credit
                              tax
                              discountedUsage
                              fee
                              refund
                              riFee
                              savingPlanRecurringFee
                              savingPlanUpfrontFee
                              savingsPlanCoverage
                              savingsPlanNegation
                            }
                            _5 {
                              cost
                              credit
                              tax
                              discountedUsage
                              fee
                              refund
                              riFee
                              savingPlanRecurringFee
                              savingPlanUpfrontFee
                              savingsPlanCoverage
                              savingsPlanNegation
                            }
                            _6 {
                              cost
                              credit
                              tax
                              discountedUsage
                              fee
                              refund
                              riFee
                              savingPlanRecurringFee
                              savingPlanUpfrontFee
                              savingsPlanCoverage
                              savingsPlanNegation
                            }
                            _7 {
                              cost
                              credit
                              tax
                              discountedUsage
                              fee
                              refund
                              riFee
                              savingPlanRecurringFee
                              savingPlanUpfrontFee
                              savingsPlanCoverage
                              savingsPlanNegation
                            }
                            _8 {
                              cost
                              credit
                              tax
                              discountedUsage
                              fee
                              refund
                              riFee
                              savingPlanRecurringFee
                              savingPlanUpfrontFee
                              savingsPlanCoverage
                              savingsPlanNegation
                            }
                            _9 {
                              cost
                              credit
                              tax
                              discountedUsage
                              fee
                              refund
                              riFee
                              savingPlanRecurringFee
                              savingPlanUpfrontFee
                              savingsPlanCoverage
                              savingsPlanNegation
                            }
                            _10 {
                              cost
                              credit
                              tax
                              discountedUsage
                              fee
                              refund
                              riFee
                              savingPlanRecurringFee
                              savingPlanUpfrontFee
                              savingsPlanCoverage
                              savingsPlanNegation
                            }
                            _11 {
                              cost
                              credit
                              tax
                              discountedUsage
                              fee
                              refund
                              riFee
                              savingPlanRecurringFee
                              savingPlanUpfrontFee
                              savingsPlanCoverage
                              savingsPlanNegation
                            }
                          }
                        }
                        error {
                          code
                          message
                          statusCode
                        }
                      }
                    }`

    httpClient.request(
      {
        method: 'POST',
        url: GRAPHQL_URL,
        headers: {
          'x-api-key': GRAPHQL_API_KEY
        },
        data: {
          query,
          variables: {
            token,
            year
          }
        }
      },
      {
        success: (response) => {
          const {
            data: { getUsageDetailsForUserNew },
            errors
          } = response.data

          if (errors?.length) {
            failure(errors[0]?.message)
          }

          success(getUsageDetailsForUserNew)
        },
        failure: (error) => failure(error)
      }
    )
  }

  this.getCredits = ({ year, month, token }, { success, failure }) => {
    const query = `mutation ($token: String!, $year: Int!, $month: Int) {
                      getCredits(actualsSearch: {token: $token, year: $year, month: $month}) {
                        data {
                          elementMap {
                            element1Id
                            element2Id
                            element3Id
                            element4Id
                          }
                          totalCredit
                        }
                        error {
                          code
                          message
                          statusCode
                        }
                      }
                    }`

    httpClient.request(
      {
        method: 'POST',
        url: GRAPHQL_URL,
        headers: {
          'x-api-key': GRAPHQL_API_KEY
        },
        data: {
          query,
          variables: {
            token,
            year,
            month
          }
        }
      },
      {
        success: (response) => {
          const { getCredits } = response.data.data
          success(getCredits)
        },
        failure: (error) => failure(error)
      }
    )
  }
}

export default ActualsRepo
