import React, { useContext, useEffect, useMemo, useState } from 'react'
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable, useSortBy } from '@tanstack/react-table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons'
import getColumns from './AllowedAllocationsColumns'
import AppContext from 'context/AppContext'
import getAllAccounts from 'Admin/AccountMapperNew/use-cases/get-all-accounts'
import moment from 'moment'
import { getAccountStatus } from 'utils/reports-helper'
import useElementNames from 'hooks/useElementNames'
import Loader from 'components/common/Loader'

const currentYear = moment().year()

const AllowedAllocations = ({ accounts, actualsData, inputMonth, inputYear }) => {
  const elementNames = useElementNames()
  const [data, setData] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const [allAccounts, setAllAccounts] = useState([])
  const [sorting, setSorting] = useState([])
  const { repoFactory } = useContext(AppContext)
  const [isLoading, setIsLoading] = useState(false)
  const [statusFilter, setStatusFilter] = useState({
    current: true,
    past: false,
    future: false
  })

  const columns = useMemo(
    () => getColumns(elementNames, actualsData?.[0], accounts),
    [elementNames, actualsData, accounts]
  )

  const table = useReactTable({
    data: filteredData,
    columns,
    state: {
      sorting
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    initialState: {
      sorting: [
        {
          id: 'status',
          desc: false
        }
      ]
    },
    useSortBy
  })

  const getMappedData = (items) => {
    return new Promise((resolve) => {
      const dateMap = items.reduce((acc, item) => {
        const { accountId, startDate } = item
        if (!acc[accountId]) acc[accountId] = []
        acc[accountId].push(startDate)
        return acc
      }, {})

      Object.keys(dateMap).forEach((accountId) => {
        dateMap[accountId].sort((a, b) => new Date(a) - new Date(b))
      })

      const mappedItems = items.map((item) => {
        const { accountId, createdDate, startDate, endDate } = item
        const createdAt = moment(createdDate)
        const earliestStartDate = dateMap[accountId][0]
        const start =
          startDate === earliestStartDate && createdAt.year() === currentYear
            ? createdAt.format('MM-DD-YYYY')
            : startDate
        const effectiveDates = `${start} to ${endDate || 'Till-Date'}`

        return {
          ...item,
          effectiveDates,
          status: getAccountStatus(start, endDate, inputMonth, inputYear)
        }
      })

      resolve(mappedItems)
    })
  }

  useEffect(() => {
    const mapData = async () => {
      if (actualsData) {
        const data = actualsData.map((item) => ({
          ...item,
          createdDate: allAccounts.find((el) => el.accountId === item.accountId)?.createdDate
        }))
        const mappedData = await getMappedData(data) // Espera a que la promesa se resuelva
        setData(mappedData)
        setIsLoading(false)
      }
    }

    mapData()
  }, [inputMonth, actualsData, allAccounts])

  const getAccounts = () => {
    const existingAllocations = Object.values(accounts)
    getAllAccounts(
      { existingAllocations, inputYear },
      {
        accountMapperRepo: repoFactory.accountMapperRepoNew(),
        observer: {
          accountsReceived: (awsAccounts, allAccounts) => {
            setAllAccounts(allAccounts)
          },
          errorReceivingAccounts: () => {
            console.log('error')
          }
        }
      }
    )
  }

  useEffect(() => {
    setIsLoading(true)
    getAccounts()
  }, [accounts])

  useEffect(() => {
    const items = [...data]
    const { current, past, future } = statusFilter

    const filteredItems = items.filter((item) => {
      if (current && item.status === 'Current') return true
      if (past && item.status === 'Past') return true
      if (future && item.status === 'Future') return true
      return false
    })

    setFilteredData(filteredItems)
  }, [data, statusFilter])

  return (
    <>
      <div className="d-flex align-items-center gap-4 mb-2">
        <h2 className="mb-0">Account Allocation</h2>
        <div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="checkbox"
              id="currentStatus"
              checked={statusFilter.current}
              onChange={() => setStatusFilter({ ...statusFilter, current: !statusFilter.current })}
            />
            <label className="form-check-label" htmlFor="currentStatus">
              Current
            </label>
          </div>
        </div>
        <div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="checkbox"
              id="pastStatus"
              checked={statusFilter.past}
              onChange={() => setStatusFilter({ ...statusFilter, past: !statusFilter.past })}
            />
            <label className="form-check-label" htmlFor="pastStatus">
              Past
            </label>
          </div>
        </div>
        <div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="checkbox"
              id="futureStatus"
              checked={statusFilter.future}
              onChange={() => setStatusFilter({ ...statusFilter, future: !statusFilter.future })}
            />
            <label className="form-check-label" htmlFor="futureStatus">
              Future
            </label>
          </div>
        </div>
      </div>
      <div className="table-responsive position-relative" style={{ height: '330px' }}>
        {isLoading && <Loader />}
        <table className="table table-sticky-header">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <button
                        {...{
                          className: header.column.getCanSort()
                            ? 'd-flex justify-content-between align-items-center user-select-none'
                            : '',
                          onClick: header.column.getToggleSortingHandler()
                        }}
                        className="sorting-button"
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {{
                          asc: <FontAwesomeIcon className="ml-2" icon={faArrowUp} />,
                          desc: <FontAwesomeIcon className="ml-2" icon={faArrowDown} />
                        }[header.column.getIsSorted()] ?? null}
                      </button>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>
                    <>{flexRender(cell.column.columnDef.cell, cell.getContext())}</>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default AllowedAllocations
