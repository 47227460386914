import React, { useCallback, useContext, useEffect, useState } from 'react'
import AppContext from '../../../context/AppContext'
import getUserMappingAndDashboardList from '../use-cases/getUserMappingAndDashboardList'
import getQuickSightTopicURL from '../use-cases/getQuickSightTopicURL'
import { createEmbeddingContext } from 'amazon-quicksight-embedding-sdk'
import './topic.scss'
import getDashboardList from '../../../Admin/Configuration/use-cases/get-dashboard-list-use-case'

const embeddingContext = await createEmbeddingContext()

const QuickSightTopic = () => {
  const { repoFactory, theme } = useContext(AppContext)
  const [isLoading, setIsLoading] = useState(false)
  const [topicList, setTopicList] = useState([])
  const [url, setUrl] = useState()
  const [embeddedDashboardExperience, setEmbeddedDashboardExperience] = useState()
  const [isSearchBarFocused, setIsSearchBarFocused] = useState(false)
  let embeddedDashboard

  useEffect(() => {
    setIsLoading(true)
    getDashboardList(
      {
        token: localStorage.getItem('authToken')
      },
      {
        biRepo: repoFactory.biRepo(),
        observer: {
          receiveDashboardList: (response) => {
            setIsLoading(false)
            setTopicList(
              response.dashboardList
                .filter((item) => ['Topic', 'topic'].includes(item?.type))
                .map((item) => ({
                  value: item.value,
                  label: item.name
                }))
            )
          },
          errorWhileGettingDashboardList: () => {
            setIsLoading(false)
          }
        }
      }
    )
  }, [repoFactory])

  // useEffect(() => {
  //   if (embeddedDashboardExperience) {
  //     embeddedDashboardExperience
  //       .setThemeOverride(getThemePalette(theme))
  //       .then((response) => {})
  //       .catch((error) => {
  //         console.error(error)
  //       })
  //   }
  // }, [theme, embeddedDashboardExperience])

  useEffect(() => {
    if (topicList && topicList.length > 0) {
      setIsLoading(true)
      const topicIdList = topicList.map((item) => item.value)
      const themeList = ['bdcf29e2-748b-4ebf-a74e-650abdf84d63', 'dd9d0dfd-790e-432d-8fe3-7af7ce062c4c']
      getQuickSightTopicURL(
        {
          username: localStorage.getItem('username'),
          topicIdList,
          themeList
        },
        {
          biRepo: repoFactory.biRepo(),
          observer: {
            errorGettingUrl: (error) => {
              setIsLoading(false)
            },
            receiveQuickSightUrl: (response) => {
              setUrl(response.EmbedUrl)
              setIsLoading(false)
            }
          }
        }
      )
    }
  }, [topicList])

  useEffect(() => {
    if (url) {
      if (embeddedDashboardExperience) {
        document.getElementById('topic-container').removeChild(document.getElementById('topic-container').lastChild)
        setEmbeddedDashboardExperience(null)
      }
      updateDashboard(url)
    }
  }, [url])

  const updateDashboard = useCallback(
    async (url) => {
      try {
        const frameOptions = {
          url,
          container: '#topic-container',
          resizeHeightOnSizeChangedEvent: false,
          width: '100%',
          height: '700px',
          onChange: (changeEvent, metadata) => {
            switch (changeEvent.eventName) {
              case 'FRAME_MOUNTED': {
                console.log('Do something when the experience frame is mounted.')
                break
              }
              case 'FRAME_LOADED': {
                console.log('Do something when the experience frame is loaded.', metadata)

                break
              }
            }
          }
        }

        const contentOptions = {
          // locale: 'en-US',
          panelOptions: {
            panelType: 'SEARCH_BAR',
            focusedHeight: '250px',
            expandedHeight: '700px'
          },
          hideTopicName: false,
          hideIcon: true,
          showTopicName: true,
          showPinboard: true,
          allowTopicSelection: true,
          allowFullscreen: true,
          searchPlaceholderText: 'Ask about your spending?',
          themeOptions: {
            themeArn: `arn:aws:quicksight:us-east-1:422411878635:theme/${
              theme === 'dark' ? 'bdcf29e2-748b-4ebf-a74e-650abdf84d63' : 'dd9d0dfd-790e-432d-8fe3-7af7ce062c4c'
            }`
          },
          onMessage: async (messageEvent, experienceMetadata) => {
            switch (messageEvent.eventName) {
              case 'Q_SEARCH_OPENED': {
                console.log('Do something when SEARCH_BAR type panel is expanded')
                break
              }
              case 'Q_SEARCH_FOCUSED': {
                console.log('Do something when SEARCH_BAR type panel is focused')
                setIsSearchBarFocused(true)
                break
              }
              case 'Q_SEARCH_CLOSED': {
                console.log('Do something when SEARCH_BAR type panel is collapsed')
                setIsSearchBarFocused(false)
                break
              }
              case 'Q_PANEL_ENTERED_FULLSCREEN': {
                console.log('Do something when panel enters full screen mode')
                break
              }
              case 'Q_PANEL_EXITED_FULLSCREEN': {
                console.log('Do something when panel exits full screen mode')
                break
              }
              case 'CONTENT_LOADED': {
                console.log('All visuals are loaded. The title of the document:', messageEvent.message)
                setEmbeddedDashboardExperience(embeddedDashboard)
                embeddedDashboard && embeddedDashboard?.close()
                break
              }
              case 'ERROR_OCCURRED': {
                console.log(
                  'Error occurred while rendering the experience. Error code:',
                  messageEvent.message.errorCode
                )
                setEmbeddedDashboardExperience(embeddedDashboard)
                break
              }
            }
          }
        }

        embeddedDashboard = await embeddingContext.embedGenerativeQnA(frameOptions, contentOptions)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    },
    [url, setEmbeddedDashboardExperience]
  )
  return <div id="topic-container" className={theme === 'dark' ? 'dark' : 'light'}></div>
}

export default QuickSightTopic
